<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.query"
            clearable
            @clear="getUserList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getUserList"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true"
            >添加用户</el-button
          >
        </el-col>
      </el-row>
      <!-- 用户列表区 -->
      <el-table :data="userlist" style="margin-top: 20px" border stripe>
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="username" label="姓名"></el-table-column>
        <el-table-column prop="email" label="邮箱"></el-table-column>
        <el-table-column prop="mobile" label="电话"></el-table-column>
        <el-table-column prop="role_name" label="角色"></el-table-column>
        <el-table-column label="状态">
          <template v-slot="scope">
            <el-switch
              v-model="scope.row.mg_state"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="switchChanged(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <!-- 修改按钮 -->
            <el-tooltip
              effect="dark"
              content="修改"
              placement="top"
              :enterable="false"
            >
              <el-button
                style="margin-left: 2px; margin-top: 1px"
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog"
              ></el-button>
            </el-tooltip>
            <!-- 删除按钮 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top"
              :enterable="false"
              
            >
              <el-button
                style="margin-left: 2px; margin-top: 1px"
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="removeUserById(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <!-- 编辑权限按钮 -->
            <el-tooltip
              effect="dark"
              content="权限编辑"
              placement="top"
              :enterable="false"
            >
              <el-button
                style="margin-left: 2px; margin-top: 1px"
                type="warning"
                icon="el-icon-edit"
                size="mini"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页显示区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[5,10,50,200]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        style="margin-top: 20px"
      >
      </el-pagination>
    </el-card>
    <!-- 添加用户的对话框 -->
    <el-dialog title="添加用户" :visible.sync="addDialogVisible" width="80%">
      <!-- 对话框内容主题区域 -->
      <el-form
        ref="addFormRef"
        :model="addForm"
        :rules="addFormRules"
        label-width="70px"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
        <el-form-item label="手机" prop="mobile">
          <el-input v-model="addForm.mobile"></el-input>
        </el-form-item>
      </el-form>

      <!-- 对话框底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户信息的对话框 -->
    <el-dialog
      title="修改用户"
      :visible.sync="editDialogVisible"
      width="30%"
    >
      <span>修改用户</span>
      <!-- 修改用户信息对话框底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //获取用户列表的参数对象
      queryInfo: {
        query: "",
        //当前的页码数
        pagenum: 1,
        //当前每页显示多少条数据
        pagesize: 5,
      },
      userlist: [],
      total: 0,
      addDialogVisible: false,
      //添加用户的表单数据
      addForm: {
        username: "realUser",
        password: "123456",
        email: "1233@qq.com",
        mobile: "123",
      },
      addFormRules: {
        username: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: ["blur", "change"],
          },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10个字符之间",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
          {
            min: 6,
            max: 18,
            message: "长度在 6 到 15个字符之间",
            trigger: ["blur", "change"],
          },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: ["blur", "change"],
          },
          {
            min: 3,
            max: 11,
            message: "请输入正确的手机号",
            trigger: ["blur", "change"],
          },
        ],
      },
      editDialogVisible:false,//编辑框的默认展开
    };
  },
  created() {
    this.getUserList();
  },
  methods: {
    async getUserList() {
      const { data: res } = await this.$http.get("users", {
        params: this.queryInfo,
      });
      if (res.meta.status !== 200)
        return this.$message.error("获取用户列表失败！");
      this.userlist = res.data.users;
      this.total = res.data.total;
      console.log("获取用户列表成功！");
      console.log(res);
      this.addForm.username ='admin'+Date.now()%10000;
    },

    //每页显示数量改变函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      console.log("每页显示：" + newSize);
      this.getUserList();
    },
    //页码值改变事件
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      console.log("跳转到第几页：" + newPage);
      this.getUserList();
    },

    async switchChanged(userinfo) {
      const { data: res } = await this.$http.put(
        `users/${userinfo.id}/state/${userinfo.mg_state}`
      );
      if (res.meta.status != 200) {
        userinfo.mg_state = !userinfo.mg_state;
        return this.$message.error("更新用户状态失败：" + res.meta.msg);
      } else {
        this.$message.success("更新用户状态成功！");
      }
    },
    addUser() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post("users", this.addForm);
        console.log(res);
        if (res.meta.status != 201) return this.$message.error(res.meta.msg);
        this.$message.success(res.meta.msg);
        this.addDialogVisible = false; //关闭添加用户窗口
        this.getUserList(); //重新获取用户列表
      });
    },
    showEditDialog() {
      console.log("编辑按钮按下！");
      this.editDialogVisible = true
    },
    //根据id删除对应的id值
    removeUserById(id){
      //弹框询问是否删除数据
     this.$confirm('此操作将永久删除该用户, 是否继续?', '注意', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async ()=>{
          console.log("确认了删除")
        const{data:res} = await this.$http.delete('/users/'+id)
      
        if (res.meta.status != 200){return this.$message.error(res.meta.msg);}
        else{this.$message.success(res.meta.msg)}
        this.getUserList();
        }).catch(()=>{
          console.log('取消了删除')
          this.$message.info("已取消删除！")
        })
        
    }
  },
};
</script>


<style lang="less" scoped>
</style>

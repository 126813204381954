import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/login.vue'
import Home from '../components/home.vue'
import Welcome from '../components/welcome.vue'
import Users from '../components/user/users.vue'
import Rights from '../components/power/Rights.vue'
import Roles from '../components/power/Roles.vue'



Vue.use(VueRouter)

const routes = [
  { path: '/login', name: 'Login', component: Login, },
  { path: '/', redirect: '/login', },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/users', component: Users },
      { path: '/rights', component: Rights },
      { path: '/roles', component: Roles },

    ]
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/* //挂载路由导航守卫--
router.beforeEach((to, from, next) => {
  //to 将要访问的路径
  //from 从哪个路径来的
  //next 是一个函数，表示放行
  //1直接跳转next（）
  //2强制重定向next（/url）

  if (to.path == "/login") return next();
  //const cookieStr = document.cookie;//获取cookie
  const tokenStr = window.sessionStorage.getItem('token');//获取token
  if (!tokenStr) {
    alert('请先登陆！')
    next('/login')
  }
  next();
})

 */

export default router

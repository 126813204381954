<template>
  <el-container class="home-container">
    <!-- 头部区域 -->
    <el-header height="56px">
      <div>
        <img src="../assets/img/logo.e1bc3747.png" alt="" />
        <span>后台管理</span>
      </div>
      <el-button type="info" @click="logout" size="mini">退出</el-button>
    </el-header>
    <!-- 页面主体 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '160px'">
        <!--动态调整侧边栏的宽度 :width="isCollapse ? '64px' : '200px'" -->
        <div class="toggle-button" @click="togleCollapse">|||</div>
        <!-- 侧边栏菜单区域 -->
        <el-menu
          background-color="#333744"
          text-color="#fff"
          active-text-color="#409eff"
          :collapse="isCollapse"
          :collapse-transition="false"
          router
          :default-active="$route.path"
        >
          <!-- 一级菜单 -->
          <el-submenu
            :index="item.id + ''"
            v-for="item in menulist"
            :key="item.id"
          >
            <!-- 一级菜单的模板区域 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="iconsObj[item.id]"></i>
              <!-- 文本 -->
              <span>{{ item.authName }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item
              :index="'/' + subItem.path + ''"
              v-for="subItem in item.children"
              :key="subItem.id"
              style="width: '160px'"
            >
              <template slot="title">
                <!-- 2图标 -->
                <i class="el-icon-caret-right"></i>
                <!-- 2文本 -->
                <span>{{ subItem.authName }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 右侧内容主题 -->
      <el-main>
        <!-- 路由占位符 -->
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>


<script>
export default {
  data() {
    return {
      //左侧菜单数据
      menulist: [],
      iconsObj: {
        125: "el-icon-user",
        103: "el-icon-coordinate",
        101: "el-icon-s-shop",
        102: "el-icon-s-order",
        145: "el-icon-pie-chart",
      },
      isCollapse: false,
    };
  },
  created() {
    this.getMenuList();
  },
  methods: {
    logout() {
      document.cookie = "cookie=;expires=" + new Date(0).toGMTString(); //清空cookie操作
      window.sessionStorage.clear(); //清空session
      this.$message.warning("已退出！");
      this.$router.push("/login");
    },
    async getMenuList() {
      const { data: res } = await this.$http.get("menus");
      if (res.meta.status !== 200)
        return this.$message.warning("获取菜单列表失败：" + res.meta.msg);
      this.menulist = res.data;
      console.log(res);
    },
    togleCollapse() {
      //菜单折叠与展开按钮
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  height: 100%;
}
.el-header {
  background-color: #333744;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;

  > div {
    display: flex;
    align-items: center;
    img {
      height: 40px;
    }
    span {
      margin-left: 15px;
    }
  }
}
.el-aside {
  background-color: #333744;
  border-top-right-radius: 7px;

  .el-menu {
    border: none;
    .el-submenu {
      .el-menu-item {
        min-width: 0px;
      }
    }
  }
}
.el-main {
  background-color: #eaedf1;
  padding: 15px;
}
.iconfont {
  margin-right: 10px;
}
.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.3em;
  // 鼠标放上去变成小手
  cursor: pointer;
}
</style>
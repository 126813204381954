import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//导入element
import './plugins/element.js'
//导入全局样式表
import './assets/css/global.css'
//导入axios
import axios from 'axios'


Vue.config.productionTip = true
//axios基础配置
Vue.prototype.$http = axios
// axios.defaults.baseURL = 'https://www.liulongbin.top:8888/api/private/v1/'
axios.defaults.baseURL = 'https://sicau.work:8080/api/v1/'
//携带coookie
//axios.defaults.withCredentials = true
//设置axios拦截器（对request进行预处理）
axios.interceptors.request.use(config=>{
  //为每个请求的请求头中加入Authorization字段
  // config.headers.Authorization = window.sessionStorage.getItem('token')
  config.headers.Cookie = "mysession=MTYyMTIyNTkyOXxEdi1CQkFFQ180SUFBUkFCRUFBQV84RF9nZ0FFQm5OMGNtbHVad3dJQUFaVmMyVnlTVVFHYzNSeWFXNW5EQXNBQ1RJd01UZ3dNVFU1T1FaemRISnBibWNNQ0FBR1VtOXNaVWxFQkhWcGJuUUdBZ0FCQm5OMGNtbHVad3dVQUJKamMzSm1YM1J2YTJWdVgzTmxjM05wYjI0R2MzUnlhVzVuREMwQUszVTNOVXMxVkhoamJHMUtRalEzYkVSTk0xaGxOM2RGT0Rsbk1sWldja2xUUzJOTlRsbEpXamRXTW1NR2MzUnlhVzVuREJNQUVXTnpjbVpmZEc5clpXNWZhWE56ZFdWa0JXbHVkRFkwQkFZQV9NRkQ0NUk9fCnwumF11vZCVCkzsODNJRr7UVR3QdSL8lVM5JPy8_nN; Path=/; Domain=sicau.work; Expires=Mon, 24 May 2021 04:30:32 GMT;"
  return config;
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="login_page">
    <div class="login_box">
      <!-- 头像区 -->
      <div class="avatar_box">
        <img src="../assets/logo.png" alt="avatar" />
      </div>
      <!-- 登录表单 -->
      <div :gutter="10">
        <el-form
          :xs="8"
          :sm="6"
          :md="4"
          :lg="3"
          :xl="1"
          ref="loginFormRef"
          :model="loginForm"
          :rules="loginFormRules"
          label-width="5px"
          class="login_form"
        >
          <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              placeholder="账号"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              show-password
              placeholder="密码"
              prefix-icon="el-icon-lock"
            ></el-input>
          </el-form-item>
          <el-form-item class="btns">
            <el-button type="primary" @click="login">登录</el-button>
            <el-button type="info" @click="resetLoginForm">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginForm: {
        username: "fx", //Flyskea
        password: "Woaini.12", //123456a
      },
      // 表单验证
      loginFormRules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入用户密码", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "长度在 6 到 18 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 表单重置按钮
    resetLoginForm() {
      // console.log(this)
      // resetFields：element-ui提供的表单方法
      this.$refs.loginFormRef.resetFields();
    },
    login() {
      this.$refs.loginFormRef.validate((valid) => {
        if (!valid) return;
        var res = this.$http.post("login", {
          name: this.loginForm.username,
          password: this.loginForm.password,
        });
        res.then((value) => {
          this.$message.success("登录成功");
          console.log(value);
          console.log(value.headers);
          //处理CSRF
          this.$router.push("/home");
        });
        res.catch((e) => {
          if (e.response) {
            console.log(e.response.data);
            console.log(e.response.status);
            console.log(e.response.headers);
            this.$message.error(e.response.data.msg + "！");
            console.log(e.response);
          } else if (e.request) {
            if(e.request.readyState == 4){
              this.$message.error("网络错误，请检查网络或稍后再试！")
            }
          } else {
            // Something happened in setting up the request that triggered an Error
            this.$message.error("系统错误，请检查网络或稍后再试！")
          }
        });

        console.log("res：" + res);
        /*  if(res.status == 200){
        this.$message.success('登录成功');
        document.cookie = "cookie=" + res.data.data.cookies
        this.$router.push('/home')
         }else if(res.status == 400){
           console.log("出错啦");
         } */
      });
    },
    testlogin() {
      // 表单预验证
      // valid：bool类型
      this.$refs.loginFormRef.validate(async (valid) => {
        //console.log(valid)
        if (!valid) return false;
        // this.$http.post('login', this.loginForm): 返回值为promise
        // 返回值为promise，可加await简化操作 相应的也要加async
        const login_temp = await this.$http
          .post("login", {
            name: this.loginForm.username,
            password: this.loginForm.password,
          })
          .catch((e) => console.error(e));
        console.log(login_temp);
        const { data: res } = login_temp;
        // console.log(res)
        console.log(res.msg);
        if (!res.msg) return this.$message.error("登录失败!" + res.meta.msg);
        this.$message.success("登录成功");
        // 1、将登陆成功之后的token, 保存到客户端的sessionStorage中; localStorage中是持久化的保存
        //   1.1 项目中出现了登录之外的其他API接口，必须在登陆之后才能访问
        //   1.2 token 只应在当前网站打开期间生效，所以将token保存在sessionStorage中
        window.sessionStorage.setItem("token", res.data.token);
        // 2、通过编程式导航跳转到后台主页, 路由地址为：/home
        this.$router.push("/home");
      });
    },
  },
};
</script>

<style lang="less" scoped>
/* // lang="less" 支持less格式
// scoped vue的指令，只在当前组件生效 */
.login_page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* 定位是最重要的，这里就能撑满一个屏幕，而且自适应屏幕大小 */
  background: url("../assets/img/BG.jpg") no-repeat;
  background-size: cover;
  z-index: 1;
}
.login_container {
  background-color: #2b4b6b;
  height: 100%;
}
.login_box {
  width: 400px;
  height: 360px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // -webkit-transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: #343435 0px 0px 30px 5px;

  .avatar_box {
    width: 130px;
    height: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #fff;
    }
  }
}
.login_form {
  position: absolute;
  bottom: 60px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: center;
}
.info {
  font-size: 13px;
  margin: 10px 15px;
}
</style>
